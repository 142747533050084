import Vue from 'vue';

export default {
    getEmpresas(parametros){
        return Vue.axios.get("empresas/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getEmpresa(id){
        return Vue.axios.get("empresas/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateEmpresa(parametros){
        return Vue.axios.put("empresas", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateEmpresaP(parametros){
        return Vue.axios.post("empresas/update", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteEmpresa(id){
        return Vue.axios.delete("empresas/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addEmpresa(parametros){
        return Vue.axios.post("empresas", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteLogo(parametros){
        return Vue.axios.post("empresas/delete_logo", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}