import Vue from 'vue';

export default {
    async getEmpleados(parametros) {
        return Vue.axios.get("incidencias_nomina/empleados", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async getEmpleadosPTU(parametros) {
        return Vue.axios.get("empleados/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    async getTiposIncidencias(parametros) {
        return Vue.axios.get("incidencias_nomina/tipo_incidencia", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    async store(parametros) {
        return Vue.axios.post("incidencias_nomina/store", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    
    async storeIncidencias(parametros) {
        return Vue.axios.post("incidencias_nomina", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    async storeIncidenciasTemporales(parametros) {
        return Vue.axios.post("incidencias_nomina/temporal", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    async incidencias(parametros) {
        return Vue.axios.get("incidencias_nomina/periodo_incidencia", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    async incidenciasTemporales(parametros) {
        return Vue.axios.post("incidencias_nomina/listado_temporal", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    async updateEstatusIncidencia(parametros) {
        return Vue.axios.put("incidencias_nomina", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    async updateEstatusMasivo(parametros) {
        return Vue.axios.put("incidencias_nomina/update_array", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },

    async emiminacionMasiva(parametros) {
        return Vue.axios.post("incidencias_nomina/delete_array", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    async delete(id) {
        return Vue.axios.delete("incidencias_nomina/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async importarLayout(parametros) {
        return Vue.axios.post("incidencias_nomina/importar-layout", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async importarLayoutODESSA(parametros) {
        return Vue.axios.post("odesa/importar-descuento", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    async generarFondoODESSA(parametros) {
        return Vue.axios.post("odesa/fondo-ahorro", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
}