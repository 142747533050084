import Vue from 'vue';

export default{
    find(parametros) {
        return Vue.axios.get("anio-fiscal/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateAnio(parametros){
        return Vue.axios.put("anio-fiscal", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteAnio(id){
        return Vue.axios.delete("anio-fiscal/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addAnio(parametros){
        return Vue.axios.post("anio-fiscal", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    actual(id) {
        return Vue.axios.get("anio-fiscal/actual/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    empresasAnios(parametros){
        return Vue.axios.get("anio-fiscal/empresas", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}