import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("tipo_nominas/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("tipo_nominas/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.put("tipo_nominas", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("tipo_nominas/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("tipo_nominas", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    codigoTipoNomina(parametros) {
        return Vue.axios.post("tipo_nominas/codigo", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getEmpleados(parametros) {
        return Vue.axios.get("tipo_nominas/empleados", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getPeriodos(parametros) {
        return Vue.axios.get("tipo_nominas/periodos", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    storePeridos(parametros) {
        return Vue.axios.post("periodos/updateMasivo", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}