import Vue from 'vue';

export default{
    getDepartamentos(parametros){
        return Vue.axios.get("departamentos/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDepartamento(id){
        return Vue.axios.get("departamentos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateDepartamento(parametros){
        return Vue.axios.put("departamentos", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteDepartamento(id){
        return Vue.axios.delete("departamentos/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addDepartamento(parametros){
        return Vue.axios.post("departamentos", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}