import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("registro-patronal/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("registro-patronal/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.post("registro-patronal/update", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("registro-patronal/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("registro-patronal", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    bancos(parametros) {
        return Vue.axios.post("registro-patronal/bancos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteLogo(parametros){
        return Vue.axios.post("registro-patronal/delete_logo", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    getDosContratos(id) {
        return Vue.axios.get("contratos/getConfiguracionDosContratos/" + id, { params: {tipo:"nominas"} } ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    factorCalculo(id) {
        return Vue.axios.get("registro-patronal/factorCalculo/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}