import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("reportes-dinamicos/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    /*get(id) {
        return Vue.axios.get("reportes-dinamicos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },*/
    update(parametros) {
        return Vue.axios.put("reportes-dinamicos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("reportes-dinamicos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("reportes-dinamicos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    //nuevas apis reportes
    getReportes(parametros) {
        return Vue.axios.get("reportes/nominas/generales", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateReporte(parametros) {
        return Vue.axios.put("reportes/base", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteReporte(id) {
        return Vue.axios.delete("reportes/base/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addReporte(parametros) {
        return Vue.axios.post("reportes/base", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}