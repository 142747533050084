<template>
    <div>
        <ValidationProvider v-slot="{ errors }" :name="label" :rules="rules">
            <v-autocomplete
                    v-bind:label="label"
                    v-bind:placeholder="placeholder"
                    v-bind:disabled="disabled"
                    v-bind:outlined="outlined"
                    v-bind:readonly="readonly"
                    :items="items"
                    :item-text="item_text"
                    :item-value="item_value"
                    persistent-hint
                    v-model="items_value"
                    multiple
                    :error-messages="errors"
                    :loading="loading"
                >
                
                <template v-slot:prepend-item v-if="items.length > 0">
                    <v-list-item
                        ripple
                        class="py-0 my-0"
                        @click="toggleAll"
                    >
                        <v-list-item-action >
                            <v-icon :color="items_value.length > 0 ? 'indigo darken-4' : ''">
                                {{ icon }}
                            </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Seleccionar todos
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }" v-if="compress">
                    <v-chip v-if="index === 0">
                        <span>{{ item[item_text] }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text caption ml-1"
                    >
                        (+{{ items_value.length - 1 }} otros)
                    </span>
                </template>
                <template v-slot:selection="{ item, index }" v-else>
                    <v-chip :key="index">
                        <span>{{ item[item_text] }}</span>
                    </v-chip>
                </template>
            </v-autocomplete>
        </ValidationProvider>
     </div>                   
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
    components: {
        ValidationProvider,
    },
    props:{
        items: { 
            type: Array, 
            default: () => [] 
        },
        item_text: {
            type: String,
            default: "nombre"
        },
        item_value: {
            type: String,
            default: "id"
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: undefined
        },
        outlined: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        compress: {
            type: Boolean,
            default: true
        },
        rules: {
            type: [Object, String],
            default: ''
        },
    },
    data(){
        return {
            items_value : []
        };
    },
    computed: {
        likesAll() {
            return this.items_value.length === this.items.length
        },
        likesSome() {
            return this.items_value.length > 0 && !this.likesAll
        },
        icon () {
            if (this.likesAll) return 'mdi-close-box'
            if (this.likesSome) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    watch: {
        items:function(){
            this.items_value = [];
        },
        items_value:function(val,old){
            this.$emit("dataMultiple",val);
            this.items_value_old = old;
        }
    },
    methods: {
       /**
         * @method toggleAll Este método selecciona todos los items o los quita.
         * @description Método que realiza el almacenamiento de todos los item_value:prop de los items listados en el array items_value[] o quitar todos los item_value:prop de los items.
        */
        toggleAll () {
            this.$nextTick(() => {
                if (this.likesAll) {
                    this.items_value = [];
                } else {
                    this.items_value = [];
                    if(this.items.length > 0 ){
                        for (let i = 0; i < this.items.length; i++) {
                            this.items_value.push(this.items[i][this.item_value]);
                        }
                    }
                }
            })
        },
        setData(data){
            this.items_value = data;
        }
    },
    
};
</script>